import { MY_COACH_SETTINGS_PAGE } from 'constants/pages';
import { ButtonLink } from 'components/Button';

export default function () {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-color-bg-lightmode-primary bg-opacity-60 px-4 backdrop-blur-md dark:bg-color-bg-darkmode-primary">
      <div className="mx-auto max-w-xl rounded-lg bg-color-bg-lightmode-primary p-6 px-20 py-10 shadow-lg dark:bg-color-bg-darkmode-primary">
        <h2 className="typography-product-heading-compact mb-4 text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          Set your schedule to get bookings
        </h2>
        <p className="typography-product-body mb-6 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          It only takes 2 minutes to get set up and start earning. You will not be discoverable on
          the marketplace and players can't book lessons until you complete your schedule and set a
          payout method.
        </p>
        <div className="group flex justify-center gap-ds-lg">
          <ButtonLink
            variant="brand"
            size="lg"
            className="max-w-max" // Set max-width to content
            href={MY_COACH_SETTINGS_PAGE}
          >
            <div className="typography-product-button-label-medium">Set Schedule</div>
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}
