import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Link from 'next/link';
import { MY_SCHEDULE_PAGE, getLessonPageUrl } from 'constants/pages';
import { NEW_LESSON_PAGE } from 'constants/pages';
import { GetActiveOwnerUpcomingLessonsByIdQuery } from 'types/generated/client';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import { ButtonLink } from 'components/Button';
import EmptyActionCard from 'components/EmptyActionCard';
import CreateLesson from './CreateLesson';
import LessonCard from './LessonCard';
import { LessonProps, LessonsProps } from '../types';

const UpcomingLessons: React.FC<LessonsProps> = ({ lessons, weeklyLessonsCount }) => {
  const [sliceCount, setSliceCount] = useState(2);

  useEffect(() => {
    // Function to update slice count based on screen size
    const updateSliceCount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 320 && screenWidth < 1440) {
        setSliceCount(2);
      } else if (screenWidth >= 1440) {
        setSliceCount(3);
      } else {
        setSliceCount(3);
      }
    };

    // Call update function on resize
    const debouncedUpdateSliceCount = debounce(updateSliceCount, 300);

    window.addEventListener('resize', debouncedUpdateSliceCount);

    // Initial call
    updateSliceCount();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', debouncedUpdateSliceCount);
  }, []);

  return (
    <div className="flex h-full w-full flex-col gap-6 border-b">
      <div className="flex w-full self-stretch xs:flex-col xs:items-start md:flex-row md:items-center md:justify-between">
        <h6 className="md:typography-product-heading-compact xs:typography-product-subheading w-full text-color-text-lightmode-primary dark:text-color-text-darkmode-primary md:items-center">
          Upcoming lessons
        </h6>
        {/* Mobile */}
        <div className="inline w-full md:hidden">
          {lessons && lessons.length > 0 ? (
            lessons?.slice(0, sliceCount).map((lesson: LessonProps['lesson'], index: number) => {
              return (
                <Link key={lesson.id} className="block w-full" href={getLessonPageUrl(lesson.id)}>
                  <LessonCard key={index} lesson={lesson} />
                </Link>
              );
            })
          ) : (
            <EmptyActionCard
              description="Currently, you do not have any activities."
              playersCount={weeklyLessonsCount}
              actionUrl={NEW_LESSON_PAGE}
              actionText="Create lesson"
            />
          )}
        </div>

        <div className="flex w-full flex-row gap-4 xs:my-8 xs:items-center xs:justify-center md:my-0 md:items-end md:justify-end">
          <ButtonLink
            href={MY_SCHEDULE_PAGE}
            variant="secondary"
            isInline
            sizeMobile="sm"
            sizeDesktop="md"
            className="md:w-22 md:h-11"
          >
            See my schedule
          </ButtonLink>
          <div className="md:hidden">
            {lessons && lessons.length > 0 && (
              <ButtonLink
                href={NEW_LESSON_PAGE}
                variant="brand"
                isInline
                sizeMobile="sm"
                sizeDesktop="md"
                className="md:w-22 md:h-11"
                iconLeft={<PlusWithoutBG className="h-6 w-6 cursor-pointer" />}
              >
                Create lesson
              </ButtonLink>
            )}
          </div>
        </div>
      </div>
      {/* Desktop */}
      <div className="mb-12 xs:hidden md:flex md:justify-between">
        {lessons && lessons.length > 0 ? (
          <div className="w-full md:flex md:flex-col md:justify-between lg:flex-row">
            <div className="flex flex-row justify-between lg:gap-24">
              {lessons?.slice(0, sliceCount).map((lesson: LessonProps['lesson'], index: number) => {
                return (
                  <Link key={lesson.id} className="block w-full" href={getLessonPageUrl(lesson.id)}>
                    <LessonCard key={index} lesson={lesson} />
                  </Link>
                );
              })}
            </div>
            <CreateLesson />
          </div>
        ) : (
          <EmptyActionCard
            description="Currently, you do not have any activities."
            playersCount={weeklyLessonsCount}
            actionUrl={NEW_LESSON_PAGE}
            actionText="Create lesson"
          />
        )}
      </div>
    </div>
  );
};

export default UpcomingLessons;
