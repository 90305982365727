import { GetServerSideProps } from 'next';
import { ACCOUNT_TYPE_COOKIE, AccountType } from 'constants/user';
import { CoachStatusEnum } from 'types/generated/client';
import { getTournamentsForMarketplace } from 'services/server/graphql/queries/getTournamentsForMarketplace';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useViewer } from 'hooks/useViewer';
import CoachHome from 'screens/CoachHome';
import ExploreTournaments from 'screens/ExploreTournaments';
import PlayerHome from 'screens/PlayerHome';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req } = context;

  // Access cookies from the request
  const cookies = req.cookies;

  const accountType = cookies?.[ACCOUNT_TYPE_COOKIE] || '';

  // Example: Use cookies to fetch user-specific data or pass them to your query
  const tournaments =
    accountType === AccountType.Coach ? null : await getTournamentsForMarketplace();

  return {
    props: {
      accountType,
      tournaments,
    },
  };
};

const Page = ({ accountType, tournaments }: { accountType: string; tournaments: any }) => {
  const { isAnonymousSession, isSessionLoading } = useViewer();
  const { user } = useGetCurrentUser();

  const isServerCoach = accountType === AccountType.Coach;
  const isServerPlayer = accountType === AccountType.Player;
  /**
   * @todo we'll use this later in the new player home page to determine what to show
   */
  const isServerAnonymous = !isServerCoach && !isServerPlayer;

  if (
    (!user && isServerCoach) ||
    (isSessionLoading && isServerCoach) ||
    user?.coachStatus === CoachStatusEnum.Active
  ) {
    return <CoachHome />;
  } else if (
    (!user && isServerPlayer) ||
    (isSessionLoading && isServerPlayer) ||
    // @ts-ignore This seems right so I need to confirm
    (!!user?.coachStatus && user?.coachStatus !== CoachStatusEnum.Active)
  ) {
    return <PlayerHome isServerPlayer={isServerPlayer} isServerAnonymous={isServerAnonymous} />;
  }

  // Handle any other cases or return a default component
  return <PlayerHome isServerPlayer={isServerPlayer} isServerAnonymous={isServerAnonymous} />;
};

export default Page;
