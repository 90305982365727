import React from 'react';
import { useRouter } from 'next/router';
import { AuthStatus } from 'constants/auth';
import {
  COACH_HOME_PAGE,
  MY_PLAYERS_PAGE,
  MY_SCHEDULE_PAGE,
  MY_YOU_PAGE,
  ROOT_PAGE,
  getCoachPageUrl,
} from 'constants/pages';
import { CoachStatusEnum } from 'types/generated/client';
import { useAuthModals } from 'hooks/useAuthModals';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import { useViewer } from 'hooks/useViewer';
import Calendar from 'svg/Calendar';
import Home from 'svg/Home';
import Players from 'svg/Players';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import UserCircle from 'svg/UserCircle';
import { Button } from 'components/Button';
import Link from 'components/Link';
import TransitionFadeIn from 'components/TransitionFadeIn';
import ModalCoachActions from 'components/modals/ModalCoachActions';
import ModalPlayerActions from 'components/modals/ModalPlayerActions';
import classNames from 'styles/utils/classNames';

interface Props {
  aboveTabContent?: React.ReactNode;
  tabs?: {
    Icon: React.FC<{ className?: string }>;
    text: string;
    href: string;
  }[];
  handleSignupSuccess?: ({ userId }: { userId: string }) => void | Promise<void>;
}

const TabBar: React.FC<Props> = ({ aboveTabContent, tabs = [], handleSignupSuccess }) => {
  const router = useRouter();
  const viewer = useViewer();
  const { ModalLogin, ModalSignup, openSignupModal, openLoginModal } = useAuthModals();
  const { user, loading, called } = useGetCurrentUser();
  const isViewerLoaded = viewer.status !== AuthStatus.Loading;
  const isAnon = viewer.status === AuthStatus.Anonymous;
  const isUser = viewer.status === AuthStatus.User;
  const isAnonLoaded = isViewerLoaded;
  const isUserLoaded = isViewerLoaded && !loading && called;
  const isShowing = (isAnon && isAnonLoaded) || (isUser && isUserLoaded);
  const {
    isOpen: isModalCoachActionsOpen,
    openModal: openCoachActionsModal,
    closeModal: closeCoachActionsModal,
  } = useModal();

  const triggerPage = (url: string) => {
    if (isAnon) {
      openLoginModal();
    } else {
      router.push(url);
    }
  };

  const {
    isOpen: isModalPlayerActionsOpen,
    openModal: openPlayerActionsModal,
    closeModal: closePlayerActionsModal,
  } = useModal();
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;

  const NavLink = ({
    href,
    children,
    isActive,
    Icon,
  }: {
    href: string;
    children: React.ReactNode;
    isActive?: boolean;
    isInvert?: boolean;
    Icon?: React.FC<{ className?: string }>;
  }) => {
    return (
      <Link
        href={href}
        className={classNames(
          `typography-product-tabbar-mobile flex flex-col items-center gap-1 px-5 pb-1 pt-2`,
          isActive
            ? 'text-color-text-brand dark:text-color-text-brand'
            : 'text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary',
        )}
      >
        {Icon && (
          <Icon
            className={classNames(
              'h-6 w-6',
              isActive
                ? 'text-color-text-brand dark:text-color-text-brand'
                : 'text-color-text-darkmode-secondary dark:text-color-text-lightmode-secondary',
            )}
          />
        )}
        {children}
      </Link>
    );
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 z-20 w-full md:hidden">
        {aboveTabContent}
        <div className="w-full border-t border-color-border-input-lightmode bg-color-bg-lightmode-primary dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary">
          <TransitionFadeIn className="flex h-full w-full items-center" isShowing={isShowing}>
            <div className="flex w-full items-center justify-center overflow-hidden">
              <div className="fixed bottom-0 left-0 right-0 flex h-16 border-t border-color-border-input-lightmode bg-color-bg-lightmode-primary dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary">
                <div className="relative z-10 flex w-full justify-around">
                  {!isCoach && (
                    <>
                      <NavLink
                        isActive={router.pathname === ROOT_PAGE}
                        href={ROOT_PAGE}
                        Icon={Home}
                      >
                        Home
                      </NavLink>
                      <div className="relative bottom-10">
                        <div className="relative flex h-20 w-20 items-center justify-center rounded-full bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary">
                          <div className="absolute left-0 top-0 h-10 w-full rounded-t-full border border-b-0" />

                          <Button
                            variant="brand"
                            className="h-11 w-11 shrink-0 grow-0 rounded-full border-none px-3 shadow-lg"
                            onClick={() => openPlayerActionsModal(true)}
                            isInline
                            size="icon"
                            iconLeft={<PlusWithoutBG className="h-6 w-6 cursor-pointer" />}
                          />
                        </div>
                      </div>
                      <NavLink
                        isActive={router.pathname === MY_YOU_PAGE}
                        href={MY_YOU_PAGE}
                        Icon={UserCircle}
                      >
                        You
                      </NavLink>
                    </>
                  )}
                  {isCoach && (
                    <>
                      <NavLink
                        isActive={router.pathname === COACH_HOME_PAGE}
                        href={COACH_HOME_PAGE}
                        Icon={Home}
                      >
                        Home
                      </NavLink>
                      <NavLink
                        isActive={router.pathname === MY_SCHEDULE_PAGE}
                        href={MY_SCHEDULE_PAGE}
                        Icon={Calendar}
                      >
                        Schedule
                      </NavLink>
                      <div className="relative bottom-10">
                        <div className="relative flex h-20 w-20 items-center justify-center rounded-full bg-color-bg-lightmode-primary dark:bg-color-bg-darkmode-primary">
                          <div className="absolute left-0 top-0 h-10 w-full rounded-t-full border border-b-0" />

                          <Button
                            variant="brand"
                            className="h-11 w-11 shrink-0 grow-0 rounded-full border-none px-3 shadow-lg"
                            size="icon"
                            onClick={() => openCoachActionsModal(true)}
                            isInline
                            iconLeft={<PlusWithoutBG className="h-6 w-6 cursor-pointer" />}
                          />
                        </div>
                      </div>
                      <NavLink
                        isActive={router.pathname === MY_PLAYERS_PAGE}
                        href={MY_PLAYERS_PAGE}
                        Icon={Players}
                      >
                        Players
                      </NavLink>
                      <NavLink
                        isActive={router.asPath === getCoachPageUrl(user.username || user.id)}
                        href={getCoachPageUrl(user?.username || user?.id)}
                        Icon={UserCircle}
                      >
                        Listing
                      </NavLink>
                    </>
                  )}
                </div>
              </div>
            </div>
          </TransitionFadeIn>
        </div>
      </div>
      <div className="h-16 md:hidden">&nbsp;</div>

      <ModalSignup handleSignupSuccess={handleSignupSuccess} isShowLoginLink />
      <ModalLogin isShowSignupLink />
      <ModalCoachActions
        isOpen={isModalCoachActionsOpen}
        closeModal={closeCoachActionsModal}
        title="Actions"
      />
      <ModalPlayerActions
        isOpen={isModalPlayerActionsOpen}
        closeModal={closePlayerActionsModal}
        title="Actions"
        triggerPage={triggerPage}
      />
    </>
  );
};

export default TabBar;
