import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { subWeeks, subYears } from 'date-fns';
import { DEFAULT_BANK_ACCOUNT_DESCRIPTION, DEFAULT_BANK_ACCOUNT_TITLE } from 'constants/user';
import {
  CoachStatusEnum,
  PaymentFulfillmentChannelsEnum,
  useGetActiveOwnerUpcomingLessonsByIdLazyQuery,
  useGetCoachCityRankLazyQuery,
  useGetCoachSettingsLazyQuery,
  useGetLessonRevenueByCoachIdLazyQuery,
  useGetWeeklyLessonsBookedLazyQuery,
  useUpdateUserDefaultCoachPaymentFulfillmentChannelMutation,
} from 'types/generated/client';
import { formatLessonsRevenueData, getOwnerRank, sumLessonOrders } from 'utils/shared/coachBuilder';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import CloseIcon from 'svg/CloseIcon';
import BankAccountPrompt from 'screens/CoachDetails/BankAccountPrompt';
import DiscoverButton from 'screens/PlayerHome/DiscoverButton';
import SafeAreaPage from 'layouts/SafeAreaPage';
import { ButtonText } from 'components/Button';
import Footer from 'components/Footer';
import Modal, { useModal } from 'components/modals/Modal';
import ModalStripeOnboarding from 'components/modals/ModalStripeOnboarding';
import Head from 'components/utilities/Head';
import PopppinsFont from 'components/utilities/PoppinsFont';
import CoachHomeMobile from './CoachHomeMobile';
import PlayerList from './components/PlayerList';
import SetYourCalendar from './components/SetYourCalendar';
import TabSwitch from './components/TabSwitch';
import UpcomingLessons from './components/UpcomingLesson';
import { Player } from './types';

function CoachHome() {
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false);

  const [selectedPlayer, setSelectedPlayer] = useState<Player>({
    id: '',
    name: '',
    profileImage: '',
    badge: false,
    years: [],
    totalLessons: 0,
    totalRevenue: 0,
  });
  const [paymentFulfillmentChannel, setPaymentFulfillmentChannel] = useState(
    PaymentFulfillmentChannelsEnum.OnPlatform,
  );

  const {
    isOpen: isBankAccountConnectedModalOpen,
    openModal: openBankAccountConnectedModal,
    closeModal: closeBankAccountConnectedModal,
  } = useModal();

  const [updateUserDefaultCoachPaymentFulfillmentChannel] =
    useUpdateUserDefaultCoachPaymentFulfillmentChannelMutation();

  const { user } = useGetCurrentUser();
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;

  const coachId = user?.id;

  const [getActiveOwnerUpcomingLessonsByIdLazyQuery, { data: upcomingLessons }] =
    useGetActiveOwnerUpcomingLessonsByIdLazyQuery();

  const [getCoachSettingsLazyQuery, { data: coachSettingsData }] = useGetCoachSettingsLazyQuery();

  const [getLessonRevenueByCoachIdLazyQuery, { data: coachLessons }] =
    useGetLessonRevenueByCoachIdLazyQuery();

  const [getWeeklyLessonsBookedLazyQuery, { data: weeklyLessons }] =
    useGetWeeklyLessonsBookedLazyQuery();

  const [getCoachCityRankLazyQuery, { data: coachRankings }] = useGetCoachCityRankLazyQuery();

  let playerData =
    coachLessons?.lessons &&
    formatLessonsRevenueData(coachLessons?.lessons || [], selectedPlayer.id);

  let weeklyLessonsCount = weeklyLessons?.lessons && sumLessonOrders(weeklyLessons);

  let rankingsData = coachRankings?.lessons && getOwnerRank(coachRankings?.lessons || [], coachId);

  const coachData = coachSettingsData?.usersByPk;
  const hasSettings = !!coachData;
  const hasAvailability = !!coachData?.coachAvailability?.length;
  const needBankInformation = !!user && !user.stripeMerchantChargesEnabled && hasAvailability;

  useEffect(() => {
    playerData =
      coachLessons?.lessons &&
      formatLessonsRevenueData(coachLessons?.lessons || [], selectedPlayer.id);
  }, [selectedPlayer.id]);

  useEffect(() => {
    if (needBankInformation) {
      openBankAccountConnectedModal();
    }
  }, [needBankInformation]);

  useEffect(() => {
    if (isCoach && coachId) {
      getActiveOwnerUpcomingLessonsByIdLazyQuery({
        variables: {
          ownerUserId: coachId,
          limit: 3,
        },
      });

      getLessonRevenueByCoachIdLazyQuery({
        variables: {
          coachId,
          dateFilter: subYears(new Date(), 1),
        },
      });

      getWeeklyLessonsBookedLazyQuery({
        variables: {
          dateFrom: subWeeks(new Date(), 1),
        },
      });

      getCoachSettingsLazyQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
          id: user.id,
        },
      });
    }
  }, [isCoach, coachId]);

  useEffect(() => {
    if (coachLessons?.lessons?.[0]?.ownerProfile?.activeCity?.id)
      getCoachCityRankLazyQuery({
        variables: { cityId: coachLessons?.lessons?.[0]?.ownerProfile?.activeCity?.id },
      });
  }, [coachLessons]);

  const selectedPlayerFunc = (player: Player) => {
    setSelectedPlayer(player);
  };

  return (
    <>
      <Head
        title={`The Pickleball App`}
        description={`Take pickleball lessons, create round robins, register for tournaments, find courts near you, and manage your pickleball group. Pickleball runs through Bounce.`}
      />
      <PopppinsFont />

      <SafeAreaPage isShowTopNav isHideSidebar>
        {/* Desktop */}
        <div className="mx-auto hidden min-h-screen w-full flex-col gap-12 overflow-x-hidden md:mt-8 md:flex lg:mt-12">
          {!!user && hasSettings && !hasAvailability && <SetYourCalendar />}
          {/* Upcoming Lessons */}
          {hasAvailability && (
            <div className="w-full flex-col items-center justify-center xs:hidden md:flex md:px-ds-2xl">
              <UpcomingLessons
                lessons={upcomingLessons?.lessons}
                weeklyLessonsCount={weeklyLessonsCount || 0}
              />
            </div>
          )}
          <div className="md:px-ds-2xl md:pb-ds-2xl">
            <div className="flex w-full flex-col items-center justify-center gap-6 rounded-xl border border-color-border-card-lightmode p-5 shadow dark:border-color-border-card-darkmode ">
              <PlayerList
                players={playerData?.players}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={selectedPlayerFunc}
              />

              <TabSwitch
                selectedPlayer={selectedPlayer}
                playerData={playerData}
                rankingsData={rankingsData}
              />
            </div>
          </div>
        </div>
        {/* Mobile */}
        <div className="overflow-x-hidden md:hidden">
          {!!user && hasSettings && !hasAvailability && <SetYourCalendar />}
          <CoachHomeMobile
            upcomingLessons={upcomingLessons?.lessons}
            playerData={playerData}
            selectedPlayer={selectedPlayer}
            setSelectedPlayer={selectedPlayerFunc}
            rankingsData={rankingsData}
            weeklyLessonsCount={weeklyLessonsCount || 0}
          />
        </div>

        {/* <div>
          <Explore></Explore>
        </div> */}

        <div className="mb-8 flex justify-center">
          <DiscoverButton />
        </div>
        <div className="overflow-x-hidden xs:hidden md:inline">
          <Footer />
        </div>
      </SafeAreaPage>
      {isCoach && !!user && needBankInformation && (
        <Modal
          isOpen={isBankAccountConnectedModalOpen}
          handleClose={closeBankAccountConnectedModal}
        >
          <ButtonText
            onClick={() => closeBankAccountConnectedModal()}
            className="absolute right-4 top-4 z-100 rounded-full p-2"
          >
            <CloseIcon className="h-5 w-5" />
          </ButtonText>
          <BankAccountPrompt
            onConnectBankAccount={() => {
              setIsPayoutModalOpen(true);
            }}
            heading={DEFAULT_BANK_ACCOUNT_TITLE}
            description={DEFAULT_BANK_ACCOUNT_DESCRIPTION}
            showButton
          />
        </Modal>
      )}
      <ModalStripeOnboarding
        isOpen={isPayoutModalOpen}
        handleClose={(didAddBankInformation) => {
          setIsPayoutModalOpen(false);
          if (user?.id) {
            if (didAddBankInformation) {
              updateUserDefaultCoachPaymentFulfillmentChannel({
                variables: {
                  id: user?.id,
                  defaultCoachPaymentFulfillmentChannel: PaymentFulfillmentChannelsEnum.OnPlatform,
                },
              }).catch((error) => Sentry.captureException(error));
              setPaymentFulfillmentChannel(PaymentFulfillmentChannelsEnum.OnPlatform);
            }
          }
        }}
      />
    </>
  );
}

export default CoachHome;
