import React from 'react';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import { ButtonLink } from 'components/Button';

interface Props {
  description: string;
  actionText: string;
  playersCount: number;
  actionUrl?: string;
  actionHandler?: () => void;
}

const Wrapper = ({
  actionUrl,
  actionHandler = () => {},
  children,
}: Pick<Props, 'actionUrl' | 'actionHandler'> & { children: React.ReactNode }) => {
  return actionUrl ? (
    <ButtonLink
      variant="brand"
      size="md"
      className="h-10"
      href={actionUrl}
      iconLeft={<PlusWithoutBG />}
    >
      {children}
    </ButtonLink>
  ) : (
    <button className="w-full" onClick={actionHandler}>
      {children}
    </button>
  );
};

export default function EmptyActionCard({
  description,
  actionText,
  playersCount,
  actionUrl,
  actionHandler,
}: Props) {
  return (
    <div className="mt-6 flex w-full grow flex-col items-center justify-center gap-6 rounded-lg border border-color-border-card-lightmode py-16 shadow dark:border-color-border-card-darkmode">
      <div className="flex flex-col gap-4 text-center">
        <p className="typography-product-body-highlight text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          {description}
        </p>
        <p className="typography-informative-quote px-2 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          This week,
          <span className="px-2 text-color-text-brand dark:text-color-text-brand">
            {playersCount}
          </span>
          Bounce {playersCount === 1 ? 'player' : 'players'} have <br /> booked a lesson.
        </p>
      </div>
      <div className="group flex items-center gap-ds-lg">
        <Wrapper actionUrl={actionUrl} actionHandler={actionHandler}>
          <div className="typography-product-button-label-medium">{actionText}</div>
        </Wrapper>
      </div>
    </div>
  );
}
