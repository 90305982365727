export enum CoachLessonRevenueTypesEnum {
  Lessons = 'LESSONS',
  Revenue = 'REVENUE',
}

export const TimeRangeOptionsEnums = [
  { label: '1M', value: '1m' },
  { label: '3M', value: '3m' },
  { label: '6M', value: '6m' },
  { label: 'All time', value: 'all' },
];
